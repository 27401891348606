import React, { createContext, useState, useCallback, useEffect } from 'react';
import useSWR from 'swr';
import fetcher from '../lib/fetcher';
import { Plan } from '../models/plan';
import useUser from '../hooks/useUser';

const initialPlan = {
  title: "Oops! You've exceeded your plan limits.",
  content: '',
  primaryAction: null,
  secondaryAction: {
    content: 'Cancel',
  },
  isError: false,
  loading: false,
  visible: false,
  type: null,
  currentPlan: null,
  plans: null,
};

export const PlanContext = createContext(null);

export const PlanProvider = ({ children }) => {
  const [plan, setPlan] = useState(initialPlan);
  const { data: plans } = useSWR<Plan[]>(`${process.env.APP_URL}/plans`, fetcher);
  const { user } = useUser({ redirectTo: '/login' });

  useEffect(() => {
    if (plans && plan.plans === null) {
      setPlan({ ...plan, plans: plans });
    }
    if (user && plan.currentPlan === null) {
      setPlan({ ...plan, currentPlan: user.plan });
    }
  }, [user, plan, plans]);

  const clearUploads = useCallback(() => {
    setPlan((prevState) => ({
      ...prevState,
    }));
  }, []);

  const show = useCallback(
    (args) => {
      setPlan({ ...initialPlan, visible: true, plans: plans, currentPlan: user.plan, ...args });
    },
    [plan],
  );

  const loading = useCallback((val) => {
    setPlan((prevState) => ({
      ...prevState,
      loading: val,
    }));
  }, []);

  const hide = useCallback(() => {
    setPlan({ ...plan, visible: false });
  }, [plan]);

  return (
    <PlanContext.Provider
      value={{
        hide,
        show,
        loading,
        plan,
        clearUploads,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};
