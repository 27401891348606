import { faFile, faFileArchive, faImage, faMusic } from '@fortawesome/free-solid-svg-icons';
import { TrackFile } from '../models/track';
import { Storage, StorageAsset } from '../models/file';
import moment from 'moment';

const fileMimesToExtension = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/svg+xml': 'svg',
  'audio/wav': 'wav',
  'audio/mpeg': 'mp3',
  'audio/flac': 'flac',
  'audio/x-wav': 'wav',
  'application/pdf': 'pdf',
  'application/x-7z-compressed': '.z',
  'application/zip': 'zip',
  'application/vnd.rar': 'rar',
  'application/x-rar-compressed': 'rar',
  'application/x-rar': 'rar',
};

const fileExtensionToMime = {
  jpg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  svg: 'image/svg+xml',
  wav: 'audio/wav',
  mp3: 'audio/mpeg',
  flac: 'audio/flac',
  pdf: 'application/pdf',
  z: 'application/x-7z-compressed',
  zip: 'application/zip',
  rar: 'application/vnd.rar',
};

const fileExtensionRegex = /.(jpg|png|gif|svg|wav|mp3|flac|pdf|z|zip|rar)$/i;

export const getMimeFromFile = (filename: string): string => {
  return fileExtensionToMime[filename.match(fileExtensionRegex)[1]];
};

export const mimeToExtension = (mime: string) => {
  return fileMimesToExtension[mime] ? fileMimesToExtension[mime] : 'jpg';
};

export const isToday = (date) => {
  return moment().startOf('day').isSame(date, 'day');
};

export const isPast = (date) => {
  return moment().isAfter(date);
};
export const getTrackFromFiles = (files: TrackFile[]): StorageAsset => {
  if (files.length > 0) {
    return files.find((file) => file.referenceType === 'Main')?.file?.storage?.assets;
  }
  return null;
};

export const getTaggedFromFiles = (files: TrackFile[]): StorageAsset => {
  if (files.length > 0) {
    return files.find((file) => file.referenceType === 'Tag')?.file?.storage?.assets;
  }
  return null;
};

export const getTrackStorageFromFiles = (files: TrackFile[]): Storage => {
  if (files.length > 0) {
    return files.find((file) => file.referenceType === 'Main')?.file?.storage;
  }
  return null;
};

export const getSampleFromFiles = (files: TrackFile[]): StorageAsset => {
  if (files.length > 0) {
    return files.find((file) => file.referenceType === 'Sample')?.file?.storage?.assets;
  }
  return null;
};

export const getTrackStemFromFiles = (files: TrackFile[]): Storage => {
  if (files.length > 0) {
    return files.find((file) => file.referenceType === 'Stem')?.file?.storage;
  }
  return null;
};

export const getTrackStemFromSoundkitFiles = (files: TrackFile[]): Storage => {
  if (files.length > 0) {
    return files.find((file) => file.referenceType === 'Main')?.file?.storage;
  }
  return null;
};

export const getArtworkFromFiles = (files: TrackFile[]): StorageAsset => {
  if (files.length > 0) {
    return files.find((file) => file.referenceType === 'Artwork')?.file?.storage?.assets;
  }
  return null;
};

export const makeID = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getIconByMediaType = (type) => {
  switch (type) {
    case 'audio/wav':
    case 'audio/wave':
    case 'audio/mpeg':
    case 'audio/flac':
    case 'audio/x-wav':
      return faMusic;
    case 'image/jpeg':
    case 'image/png':
      return faImage;
    case 'application/zip':
    case 'application/x-7z-compressed':
    case 'application/vnd.rar':
    case 'application/x-rar-compressed':
    case 'application/x-rar':
      return faFileArchive;
    default:
      return faFile;
  }
};
export const isValidMime = (type) => {
  switch (type) {
    case 'audio/wav':
    case 'audio/mpeg':
    case 'audio/flac':
    case 'audio/x-wav':
      return true;
    case 'image/jpeg':
    case 'image/gif':
    case 'image/png':
      return true;
    case 'application/zip':
    case 'application/x-7z-compressed':
    case 'application/pdf':
    case 'application/vnd.rar':
    case 'application/x-rar-compressed':
    case 'application/x-rar':
      return true;
    default:
      return false;
  }
};

export const isValidExtension = (filename: string): boolean => {
  return filename.match(fileExtensionRegex) !== null;
};
export const Genres = [
  {
    label: 'All',
    value: 'all',
    subgenres: [],
  },
  {
    label: 'R&B',
    value: 'r-b',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Classic Soul',
        value: 'classic-soul',
      },
      {
        label: 'Funk',
        value: 'funk',
      },
      {
        label: 'New Soul',
        value: 'new-soul',
      },
      {
        label: 'Soul',
        value: 'soul',
      },
    ],
  },
  {
    label: 'Hip Hop',
    value: 'hip-hop',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Club',
        value: 'club',
      },
      {
        label: 'Dirty South',
        value: 'dirty-south',
      },
      {
        label: 'Old School',
        value: 'old-school',
      },
      {
        label: 'East Coast',
        value: 'east-coast',
      },
      {
        label: 'Gangster',
        value: 'gangster',
      },
      {
        label: 'Reggaeton',
        value: 'raggaeton',
      },
      {
        label: 'Underground',
        value: 'underground',
      },
      {
        label: 'West Coast',
        value: 'west-coast',
      },
      {
        label: 'Crunk',
        value: 'crunk',
      },
      {
        label: 'Orchestral',
        value: 'orchestral',
      },
      {
        label: 'Trap',
        value: 'trap',
      },
      {
        label: 'LoFi',
        value: 'lofi',
      },
    ],
  },
  {
    label: 'Pop',
    value: 'pop',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Hip Hop',
        value: 'hip-hop',
      },
      {
        label: 'R&B',
        value: 'r-b',
      },
      {
        label: 'Country',
        value: 'country',
      },
      {
        label: 'K-Pop',
        value: 'k-pop',
      },
    ],
  },
  {
    label: 'Rock',
    value: 'rock',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Alternative',
        value: 'alternative',
      },
      {
        label: 'Indie rock',
        value: 'indie-rock',
      },
      {
        label: 'Classic Rock',
        value: 'classic-rock',
      },
    ],
  },
  {
    label: 'Electronic',
    value: 'electronic',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Dance',
        value: 'dance',
      },
      {
        label: 'Techno',
        value: 'techno',
      },
      {
        label: 'House',
        value: 'house',
      },
      {
        label: 'Dub Step',
        value: 'dub-step',
      },
      {
        label: 'Break',
        value: 'break',
      },
      {
        label: 'Drum & Bass',
        value: 'drum-bass',
      },
      {
        label: '2 Step',
        value: '2-step',
      },
      {
        label: 'Trip Hop',
        value: 'trip-hop',
      },
      {
        label: 'Tropical House',
        value: 'tropical-house',
      },
    ],
  },
  {
    label: 'Reggae',
    value: 'reggae',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Afrobeat',
        value: 'afrobeat',
      },
      {
        label: 'Dancehall',
        value: 'dancehall',
      },
      {
        label: 'Raggaeton',
        value: 'raggaeton',
      },
      {
        label: 'Dub',
        value: 'dub',
      },
      {
        label: 'Roots',
        value: 'roots',
      },
    ],
  },
  {
    label: 'Country',
    value: 'country',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Alternative',
        value: 'alternative',
      },
      {
        label: 'Bluegrass',
        value: 'bluegrass',
      },
      {
        label: 'Christian',
        value: 'christian',
      },
      {
        label: 'Country Rock',
        value: 'country-rock',
      },
      {
        label: 'Cowpunk',
        value: 'cowpunk',
      },
      {
        label: 'Progressive',
        value: 'progressive',
      },
      {
        label: 'Western',
        value: 'western',
      },
    ],
  },
  {
    label: 'Classical',
    value: 'classical',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: '20th Century',
        value: '20-century',
      },
      {
        label: 'Baroque',
        value: 'baroque',
      },
      {
        label: 'Classical Period',
        value: 'classical-period',
      },
      {
        label: 'Medieval',
        value: 'medieval',
      },
      {
        label: 'Original Compositions',
        value: 'original-compositions',
      },
      {
        label: 'Romantic',
        value: 'romantic',
      },
    ],
  },
  {
    label: 'Jazz',
    value: 'jazz',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Early Jazz',
        value: 'early-jazz',
      },
      {
        label: 'Modern Jazz',
        value: 'modern-jazz',
      },
      {
        label: 'Cool Jazz',
        value: 'cool-jazz',
      },
      {
        label: 'Latin Jazz',
        value: 'latin-jazz',
      },
      {
        label: 'Smooth Jazz',
        value: 'smooth-jazz',
      },
      {
        label: 'Lounge',
        value: 'lounge',
      },
      {
        label: 'Bebop',
        value: 'bebop',
      },
      {
        label: 'Hard Bebop',
        value: 'hard-bebop',
      },
    ],
  },
  {
    label: 'Production / Film Scores',
    value: 'production-film-score',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Action',
        value: 'action',
      },
      {
        label: 'Adventure',
        value: 'adventure',
      },
      {
        label: 'Cinematic',
        value: 'cinematic',
      },
      {
        label: 'Epic',
        value: 'epic',
      },
      {
        label: 'Horror',
        value: 'horror',
      },
      {
        label: 'Drama',
        value: 'drama',
      },
      {
        label: 'Suspense',
        value: 'suspense',
      },
      {
        label: 'Trailer',
        value: 'trailer',
      },
    ],
  },
  {
    label: 'Ambient',
    value: 'ambient',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
    ],
  },
  {
    label: 'World',
    value: 'world',
    subgenres: [
      {
        label: 'None',
        value: 'none',
      },
    ],
  },
];

export const Moods = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Accomplished',
    value: 'accomplished',
  },
  {
    label: 'Adored',
    value: 'adored',
  },
  {
    label: 'Angry',
    value: 'angry',
  },
  {
    label: 'Annoyed',
    value: 'annoyed',
  },
  {
    label: 'Anxious',
    value: 'anxious',
  },
  {
    label: 'Bouncy',
    value: 'bouncy',
  },
  {
    label: 'Calm',
    value: 'calm',
  },
  {
    label: 'Confident',
    value: 'confident',
  },
  {
    label: 'Crazy',
    value: 'Crazy',
  },
  {
    label: 'Crunk',
    value: 'crunk',
  },
  {
    label: 'Depressed',
    value: 'depressed',
  },
  {
    label: 'Dirty',
    value: 'dirty',
  },
  {
    label: 'Disappointed',
    value: 'disappointed',
  },
  {
    label: 'Eccentric',
    value: 'eccentric',
  },
  {
    label: 'Energetic',
    value: 'energetic',
  },
  {
    label: 'Enraged',
    value: 'enraged',
  },
  {
    label: 'Epic',
    value: 'epic',
  },
  {
    label: 'Evil',
    value: 'evil',
  },
  {
    label: 'Flirty',
    value: 'flirty',
  },
  {
    label: 'Frantic',
    value: 'frantic',
  },
  {
    label: 'Giddy',
    value: 'giddy',
  },
  {
    label: 'Gloomy',
    value: 'gloomy',
  },
  {
    label: 'Grateful',
    value: 'grateful',
  },
  {
    label: 'Happy',
    value: 'happy',
  },
  {
    label: 'Hyper',
    value: 'hyper',
  },
  {
    label: 'Inspiring',
    value: 'inspiring',
  },
  {
    label: 'Intense',
    value: 'intense',
  },
  {
    label: 'Lazy',
    value: 'lazy',
  },
  {
    label: 'Lonely',
    value: 'lonely',
  },
  {
    label: 'Loved',
    value: 'loved',
  },
  {
    label: 'Mellow',
    value: 'mellow',
  },
  {
    label: 'Peaceful',
    value: 'peaceful',
  },
  {
    label: 'Rebellious',
    value: 'rebellious',
  },
  {
    label: 'Relaxed',
    value: 'relaxed',
  },
  {
    label: 'Sad',
    value: 'sad',
  },
  {
    label: 'Sacred',
    value: 'sacred',
  },
  {
    label: 'Silly',
    value: 'silly',
  },
];

export const getGenreBySlug = (slug: string): any => {
  for (let i = 0; i < Genres.length; i++) {
    const genre = Genres[i];
    if (genre.value === slug) return genre;
  }

  for (let i = 0; i < Genres.length; i++) {
    const genre = Genres[i];
    if (genre.subgenres) {
      const foundSubgenre = genre.subgenres.find((sub) => sub.value === slug);
      if (foundSubgenre) return foundSubgenre;
    }
  }
};

export const getMoodBySlug = (slug: string) => {
  return Moods.find((mood) => mood.value === slug);
};
export const MusicalKeys = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'A-flat minor',
    value: 'a-flat-minor',
  },
  {
    label: 'A-flat major',
    value: 'a-flat major',
  },
  {
    label: 'A minor',
    value: 'a-minor',
  },
  {
    label: 'A Major',
    value: 'a-major',
  },
  {
    label: 'A-sharp minor',
    value: 'a-sharp-minor',
  },
  {
    label: 'A-sharp major',
    value: 'a-sharp-major',
  },
  {
    label: 'B-flat minor',
    value: 'b-flat-minor',
  },
  {
    label: 'B-flat major',
    value: 'b-flat-major',
  },
  {
    label: 'B minor',
    value: 'b-minor',
  },
  {
    label: 'B major',
    value: 'b-major',
  },
  {
    label: 'C major',
    value: 'c-major',
  },
  {
    label: 'C-sharp major',
    value: 'c-sharp-major',
  },
  {
    label: 'C-sharp minor',
    value: 'c-sharp-minor',
  },
  {
    label: 'C minor',
    value: 'c-minor',
  },
  {
    label: 'C-flat minor',
    value: 'c-flat-minor',
  },
  {
    label: 'C-flat major',
    value: 'c-flat-major',
  },
  {
    label: 'D-flat major',
    value: 'd-flat-major',
  },
  {
    label: 'D-flat minor',
    value: 'd-flat-minor',
  },
  {
    label: 'D minor',
    value: 'd-minor',
  },
  {
    label: 'D major',
    value: 'd-major',
  },
  {
    label: 'D-sharp minor',
    value: 'd-sharp-minor',
  },
  {
    label: 'D-sharp major',
    value: 'd-sharp-major',
  },
  {
    label: 'E-flat minor',
    value: 'e-flat-minor',
  },
  {
    label: 'E-flat major',
    value: 'e-flat-major',
  },
  {
    label: 'E minor',
    value: 'e-minor',
  },
  {
    label: 'E major',
    value: 'e-major',
  },
  {
    label: 'F minor',
    value: 'f-minor',
  },
  {
    label: 'F major',
    value: 'f-major',
  },
  {
    label: 'F-sharp minor',
    value: 'f-sharp-minor',
  },
  {
    label: 'F-sharp major',
    value: 'f-sharp-major',
  },
  {
    label: 'G-flat major',
    value: 'g-flat-major',
  },
  {
    label: 'G minor',
    value: 'g-minor',
  },
  {
    label: 'G major',
    value: 'g-major',
  },
  {
    label: 'G-sharp minor',
    value: 'g-sharp-minor',
  },
  {
    label: 'G-sharp major',
    value: 'g-sharp-major',
  },
];

export const translateMaps = {
  all: 'All',
  'r-b': 'R&B',
  none: 'None',
  'classic-soul': 'Classic Soul',
  funk: 'Funk',
  'new-soul': 'New Soul',
  soul: 'Soul',
  'hip-hop': 'Hip Hop',
  club: 'Club',
  'dirty-south': 'Dirty South',
  'old-school': 'Old School',
  'east-coast': 'East Coast',
  gangster: 'Gangster',
  raggaeton: 'Raggaeton',
  underground: 'Underground',
  'west-coast': 'West Coast',
  crunk: 'Crunk',
  orchestral: 'Orchestral',
  trap: 'Trap',
  lofi: 'LoFi',
  pop: 'Pop',
  country: 'Country',
  'k-pop': 'K-Pop',
  rock: 'Rock',
  alternative: 'Alternative',
  'indie-rock': 'Indie rock',
  'classic-rock': 'Classic Rock',
  electronic: 'Electronic',
  dance: 'Dance',
  techno: 'Techno',
  house: 'House',
  'dub-step': 'Dub Step',
  break: 'Break',
  'drum-bass': 'Drum & Bass',
  '2-step': '2 Step',
  'trip-hop': 'Trip Hop',
  'tropical-house': 'Tropical House',
  reggae: 'Reggae',
  afrobeat: 'Afrobeat',
  dancehall: 'Dancehall',
  dub: 'Dub',
  roots: 'Roots',
  bluegrass: 'Bluegrass',
  christian: 'Christian',
  'country-rock': 'Country Rock',
  cowpunk: 'Cowpunk',
  progressive: 'Progressive',
  western: 'Western',
  cinematic: 'Cinematic',
  classical: 'Classical',
  '20-century': '20th Century',
  baroque: 'Baroque',
  'classical-period': 'Classical Period',
  medieval: 'Medieval',
  'original-compositions': 'Original Compositions',
  romantic: 'Romantic',
  jazz: 'Jazz',
  'early-jazz': 'Early Jazz',
  'modern-jazz': 'Modern Jazz',
  'cool-jazz': 'Cool Jazz',
  'latin-jazz': 'Latin Jazz',
  'smooth-jazz': 'Smooth Jazz',
  lounge: 'Lounge',
  bebop: 'Bebop',
  'hard-bebop': 'Hard Bebop',
  'production-film-score': 'Production / Film Scores',
  action: 'Action',
  adventure: 'Adventure',
  epic: 'Epic',
  horror: 'Horror',
  drama: 'Drama',
  suspense: 'Suspense',
  trailer: 'Trailer',
  ambient: 'Ambient',
  world: 'World',
  accomplished: 'Accomplished',
  adored: 'Adored',
  angry: 'Angry',
  annoyed: 'Annoyed',
  anxious: 'Anxious',
  bouncy: 'Bouncy',
  calm: 'Calm',
  confident: 'Confident',
  Crazy: 'Crazy',
  depressed: 'Depressed',
  dirty: 'Dirty',
  disappointed: 'Disappointed',
  eccentric: 'Eccentric',
  energetic: 'Energetic',
  enraged: 'Enraged',
  evil: 'Evil',
  flirty: 'Flirty',
  frantic: 'Frantic',
  giddy: 'Giddy',
  gloomy: 'Gloomy',
  grateful: 'Grateful',
  happy: 'Happy',
  hyper: 'Hyper',
  inspiring: 'Inspiring',
  intense: 'Intense',
  lazy: 'Lazy',
  lonely: 'Lonely',
  loved: 'Loved',
  mellow: 'Mellow',
  peaceful: 'Peaceful',
  rebellious: 'Rebellious',
  relaxed: 'Relaxed',
  sad: 'Sad',
  sacred: 'Sacred',
  silly: 'Silly',
  'a-flat-minor': 'A-flat minor',
  'a-flat major': 'A-flat major',
  'a-minor': 'A minor',
  'a-major': 'A Major',
  'a-sharp-minor': 'A-sharp minor',
  'a-sharp-major': 'A-sharp major',
  'b-flat-minor': 'B-flat minor',
  'b-flat-major': 'B-flat major',
  'b-minor': 'B minor',
  'b-major': 'B major',
  'c-minor': 'C minor',
  'c-major': 'C major',
  'c-sharp-major': 'C-sharp major',
  'c-sharp-minor': 'C-sharp minor',
  'c-flat-minor': 'C-flat minor',
  'c-flat-major': 'C-flat major',
  'd-flat-major': 'D-flat major',
  'd-minor': 'D minor',
  'd-major': 'D major',
  'd-flat-minor': 'D-flat minor',
  'd-sharp-major': 'D-sharp major',
  'd-sharp-minor': 'D-sharp minor',
  'e-flat-minor': 'E-flat minor',
  'e-flat-major': 'E-flat major',
  'e-minor': 'E minor',
  'e-major': 'E major',
  'f-minor': 'F minor',
  'f-major': 'F major',
  'f-sharp-minor': 'F-sharp minor',
  'f-sharp-major': 'F-sharp major',
  'g-flat-major': 'G-flat major',
  'g-minor': 'G minor',
  'g-major': 'G major',
  'g-flat-minor': 'G-flat minor',
  'g-sharp-minor': 'G-sharp minor',
  'g-sharp-major': 'G-sharp major',
};
