import useSWR from 'swr';
import fetcher, { redirect } from '../lib/fetcher';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import * as qs from 'qs';
import { Redirect } from '@shopify/app-bridge/actions';

const COMMON_ROUTES = ['/login', '/signup', '/shopify/callback'];

export default function useUser({ redirectTo = null } = {}) {
  const { data, mutate, error, isValidating } = useSWR<any>(
    `${process.env.APP_URL}/users`,
    fetcher,
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );

  const loading = !data && !error;
  const loggedOut = isValidating ? false : error ? error.statusCode === 401 : false;

  const updateRequired = data && data.updateRequired;
  const router = useRouter();

  const redirectToShopifyAuth = async () => {
    const { shop, host } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (!shop) {
      // redirect to login pagae if the shop is not found
      router.push('/login');
      return;
    }
    const result = await fetch(`${process.env.APP_URL}/shopify/auth?shop=${shop}`);
    const { url } = await result.json();
    redirect(Redirect.Action.REMOTE, shop as string, url, host as string);
  };

  useEffect(() => {
    if (
      loggedOut &&
      redirectTo &&
      router.pathname !== redirectTo &&
      !COMMON_ROUTES.includes(router.pathname)
    ) {
      redirectToShopifyAuth();
    }
  }, [loggedOut, redirectTo, redirectToShopifyAuth]);

  return {
    loading,
    loggedOut,
    user: data,
    mutate,
    updateRequired,
  };
}
